

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetNewsService {

  private apiUrlA = environment.baseUrl + '/News';

  constructor(private http: HttpClient) { }

  getNews(): Observable<any> {
    return this.http.get<any>(this.apiUrlA).pipe(
      catchError(this.handleError<any>('getNews', []))
    );
  }

  getSingleNews(id: string): Observable<any> {
    // 8241263625
    return this.http.get<any>(this.apiUrlA + '/' + id).pipe(
      catchError(this.handleError<any>('getNews', []))
    );
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
}
